import { createStore } from 'vuex'
// vuex 创建了一个全局唯一的仓库，用来存放全局的数据
const setLocalStorage = (state) => {
  const cartListString = JSON.stringify(state.cartList)
  localStorage.cartList = cartListString
  const pickUpString = JSON.stringify(state.pickUpModel)
  localStorage.pickUpModel = pickUpString
  const originAddressString = JSON.stringify(state.originAddress)
  localStorage.originAddress = originAddressString
  const addressModelString = JSON.stringify(state.addressModel)
  localStorage.addressModel = addressModelString
  const orderRouteParamString = JSON.stringify(state.orderRouteParam)
  localStorage.orderRouteParam = orderRouteParamString
  const userInfoString = JSON.stringify(state.userInfo)
  localStorage.userInfo = userInfoString
}
const getLocalStorage = (obj) => {
  if (obj !== undefined) {
    return JSON.parse(obj)
  } else {
    return {}
  }
}
export default createStore({
  state: {
    buyAmount: 1,
    makeGroupId: '',
    cartList: getLocalStorage(localStorage.cartList),
    pickUpModel: getLocalStorage(localStorage.pickUpModel),
    originAddress: getLocalStorage(localStorage.originAddress),
    addressModel: getLocalStorage(localStorage.addressModel),
    selectCity: {},
    orderRouteParam: getLocalStorage(localStorage.orderRouteParam),
    carNumNormal: localStorage.carNumNormal || 0,
    userInfo: getLocalStorage(localStorage.userInfo)
  },
  mutations: {
    saveSelectCity (state, item) {
      state.selectCity = item
    },
    // 第四步，对应的mutations被执行
    getGroupId (state, groupId) {
      state.makeGroupId = groupId
    },
    changeAmount (state, carNum) {
      // 在mutations里修改数据
      state.buyAmount = carNum
    },
    selectAddress (state, item) {
      item.select = true
      state.pickUpModel = item
      setLocalStorage(state)
    },
    selectAddresssSend (state, item) {
      state.addressModel = item || {}
      state.addressModel.empty = false
      setLocalStorage(state)
    },
    changeCar (state, payload) {
      state.cartList.goodsName = payload.goodsName
      state.cartList.allPrice = payload.buyNum * payload.goodsPrice
      setLocalStorage(state)
    },
    changeUserInfo (state, payload) {
      state.userInfo = payload
      setLocalStorage(state)
    },
    changeOriginAddress (state, item) {
      state.originAddress = item
      state.originAddress.groupType = 1
      setLocalStorage(state)
    },
    addCar (state, payload, makeGroupId) {
      const cartlistInfo = state.cartList
      cartlistInfo.shopId = payload.shopId
      cartlistInfo.shopName = payload.companyShop.shopName
      cartlistInfo.goodsName = payload.goodsName
      cartlistInfo.goodsNum = state.buyAmount
      cartlistInfo.shopPrice = payload.goodsPrice
      cartlistInfo.photoUrl = payload.coverUrl
      cartlistInfo.dispatchType = 0
      cartlistInfo.showMoneyFlag = 1
      cartlistInfo.depositAmount = 0
      cartlistInfo.unit = payload.unit
      cartlistInfo.allPrice = state.buyAmount * payload.goodsPrice
      cartlistInfo.makeGroupId = state.makeGroupId
      cartlistInfo.groupId = payload.id
      cartlistInfo.groupType = payload.groupType
      state.cartList = cartlistInfo
      setLocalStorage(state)
      console.log(state.cartList)
    },
    savaOrderRouteParam (state, item) {
      state.orderRouteParam = item
      setLocalStorage(state)
    },
    changeCarNum (state, carNum) {
      // 在mutations里修改数据
      state.carNumNormal = carNum
      localStorage.carNumNormal = state.carNumNormal
    }
  },
  actions: {
    // stroe感知到出发了changeAmount的action，执行changeAmount方法
    changeAmount (store, carNum) {
      // 提交一个commit触发mutation
      store.commit('changeAmount', carNum)
    },
    changeCarNum (store, carNum) {
      store.commit('changeAmount', carNum)
    }
  },
  modules: {
  }
})
