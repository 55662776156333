<template>
  <!-- <router-view v-slot="{ Component }"  v-if="userId">
    <keep-alive include="HomeNew">
      <component :is="Component"/>
    </keep-alive>
  </router-view>
  <router-view v-if="!userId"/> -->
  <router-view/>
</template>
<script>
export default {
  created () {
    this.userId = localStorage.userId
  }
}
</script>

<style lang="scss" scope>
@import './style/viriables.scss';
</style>
