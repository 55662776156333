import { createRouter, createWebHashHistory } from 'vue-router'
import { getCookie } from '../units/tools'

const routes = [
  {
    path: '/',
    name: 'HomeNew',
    meta: { title: '首页' },
    component: () => import(/* webpackChunkName: 'homenew_01' */ '../views/homeNew/HomeNew')
  },
  {
    path: '/home',
    name: 'Home',
    meta: { title: '社区拼团' },
    component: () => import(/* webpackChunkName: 'home_01' */ '../views/home/Home')
  },
  {
    path: '/city',
    name: 'City',
    component: () => import(/* webpackChunkName: 'my_01' */ '../views/address/City')
  },
  {
    path: '/address',
    name: 'Address',
    meta: { title: '地址管理' },
    component: () => import(/* webpackChunkName: 'my_01' */ '../views/address/Address')
  },
  {
    path: '/addAddress',
    name: 'AddAddress',
    component: () => import(/* webpackChunkName: 'my_01' */ '../views/address/AddAddress')
  },
  {
    path: '/my',
    name: 'My',
    meta: { title: '我的' },
    component: () => import(/* webpackChunkName: 'my_01' */ '../views/my/My')
  },
  {
    path: '/paysuccess',
    name: 'PaySuccess',
    component: () => import(/* webpackChunkName: 'sucss_01' */ '../views/order/PaySuccess')
  },
  {
    path: '/paytype',
    name: 'PayType',
    component: () => import(/* webpackChunkName: 'paytype_01' */ '../views/order/PayType')
  },
  {
    path: '/groupdetail/:groupId',
    name: 'GroupDetail',
    component: () => import(/* webpackChunkName: 'groupdetail_01' */ '../views/mygroup/GroupDetail')
  },
  {
    path: '/mygroup',
    name: 'Mygroup',
    component: () => import(/* webpackChunkName: 'mygroup_01' */ '../views/mygroup/Mygroup')
  },
  {
    path: '/picksite',
    name: 'PickSite',
    component: () => import(/* webpackChunkName: 'picksite_01' */ '../views/picksite/PickSite')
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import(/* webpackChunkName: 'order_01' */ '../views/order/Order')
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import(/* webpackChunkName: 'detail_01' */ '../views/home/Detail')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: '登录' },
    component: () => import(/* webpackChunkName: 'login_01' */ '../views/login/Login')
  },
  {
    path: '/register',
    name: 'Register',
    meta: { title: '注册' },
    component: () => import(/* webpackChunkName: 'register_01' */ '../views/register/Register')
  },
  {
    path: '/goodsdetail',
    name: 'GoodsDetail',
    meta: { title: '商品详情' },
    component: () => import(/* webpackChunkName: 'homenew_01' */ '../views/homeNew/Detail')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: 'search_01' */ '../views/search/Search')
  },
  {
    path: '/shopcar',
    name: 'ShopCar',
    meta: { title: '购物车' },
    component: () => import(/* webpackChunkName: 'shopCar_01' */ '../views/shopCar/ShopCar')
  },
  {
    path: '/category',
    name: 'Category',
    meta: { title: '分类' },
    component: () => import(/* webpackChunkName: 'category_01' */ '../views/category/Category')
  },
  {
    path: '/goodslist/:id',
    name: 'GoodsList',
    component: () => import(/* webpackChunkName: 'category_01' */ '../views/category/GoodsList')
  },
  {
    path: '/orderlist',
    name: 'OrderList',
    meta: { title: '订单列表' },
    component: () => import(/* webpackChunkName: 'order_01' */ '../views/order/OrderList')
  },
  {
    path: '/orderdetail',
    name: 'OrderDetail',
    component: () => import(/* webpackChunkName: 'order_01' */ '../views/order/OrderDetail')
  },
  {
    path: '/feedback',
    name: 'Feedback',
    meta: { title: '意见反馈' },
    component: () => import(/* webpackChunkName: 'order_01' */ '../views/my/Feedback')
  },
  {
    path: '/setting',
    name: 'Setting',
    meta: { title: '设置' },
    component: () => import(/* webpackChunkName: 'order_01' */ '../views/my/Setting')
  },
  {
    path: '/collection',
    name: 'Collection',
    meta: { title: '我的收藏' },
    component: () => import(/* webpackChunkName: 'my_01' */ '../views/my/Collection')
  },
  {
    path: '/coupons',
    name: 'Coupons',
    meta: { title: '优惠券' },
    component: () => import(/* webpackChunkName: 'my_01' */ '../views/my/Coupons')
  },
  {
    path: '/help',
    name: 'Help',
    meta: { title: '帮助中心' },
    component: () => import(/* webpackChunkName: 'my_01' */ '../views/my/Help')
  },
  {
    path: '/password',
    name: 'Password',
    meta: { title: '修改密码' },
    component: () => import(/* webpackChunkName: 'my_01' */ '../views/my/Password')
  },
  {
    path: '/helpDetail',
    name: 'HelpDetail',
    component: () => import(/* webpackChunkName: 'my_01' */ '../views/my/HelpDetail')
  },
  {
    path: '/newslist',
    name: 'NewList',
    component: () => import(/* webpackChunkName: 'my_01' */ '../views/news/NewsList')
  },
  {
    path: '/newsDetail/:id',
    name: 'NewsDetail',
    component: () => import(/* webpackChunkName: 'my_01' */ '../views/news/NewsDetail')
  },
  {
    path: '/confirmOrder',
    name: 'ConfirmOrder',
    meta: { title: '确认订单' },
    component: () => import(/* webpackChunkName: 'order_01' */ '../views/order/ConfirmOrder')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: 'order_01' */ '../views/Test')
  },
  {
    path: '/discountCoupon',
    name: 'DiscountCoupon',
    meta: { title: '优惠券' },
    component: () => import(/* webpackChunkName: 'order_01' */ '../views/order/DiscountCoupon')
  },
  {
    path: '/refund',
    name: 'Refund',
    component: () => import(/* webpackChunkName: 'refund_01' */ '../views/refund/Refund')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
// 路由守卫实现基础登录校验
router.beforeEach((to, from, next) => {
  const isLogin = getCookie('isLogin')
  if (isLogin || to.name === 'Login' || to.name === 'Register' || to.name === 'HomeNew' || to.name === 'GoodsDetail' || to.name === 'Category' || to.name === 'GoodsList' || to.name === 'Search' || to.name === 'Home' || to.name === 'Detail') {
    next()
  } else {
    next({ name: 'Login' })
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
