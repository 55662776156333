import { config } from './request.js'
export const parseUrlParam = urlStr => {
  const vUrl = '?' + urlStr.split('?')[1]
  const theRequest = {}
  if (vUrl.indexOf('?') !== -1) {
    let strs = vUrl.substr(1)
    strs = strs.split('&')
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1])
    }
  }
  return theRequest
}
export const getWxCode = () => {
  let redirectUrl = window.location.href
  redirectUrl = encodeURIComponent(redirectUrl)
  const appid = config.appId
  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
}
export const Debounce = (fn, t) => {
  const delay = t || 500
  let timer
  return function () {
    const args = arguments
    if (timer) {
      clearTimeout(timer)
    }
    const callNow = !timer
    timer = setTimeout(() => {
      timer = null
    }, delay)
    if (callNow) fn.apply(this, args)
  }
}
export const setCookie = (name, value) => {
  const exdays = 365
  const d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = 'expires=' + d.toGMTString()
  document.cookie = name + '=' + value + ';' + expires
  localStorage.setItem(name, value)
}

export const getCookie = (name) => {
  let ret, m
  if (typeof name === 'string' && name !== '') {
    if ((m = String(document.cookie).match(
      new RegExp('(?:^| )' + name + '(?:(?:=([^;]*))|;|$)')))) {
      ret = m[1] ? decodeURIComponent(m[1]) : ''
    }
  }
  return localStorage.getItem(name) ? localStorage.getItem(name) : ret
}
export const removeCookie = (name) => {
  setCookie(name, '')
  localStorage.removeItem(name)
}
