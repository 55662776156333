import axios from 'axios'
import md5 from 'js-md5'

export const config = {
  baseUrl: 'http://xg.bjfxtx.cn/api/',
  // baseUrl: 'http://sunjie.free.idcfengye.com/cloud_zspfsc_api_war_exploded/',
  // baseUrl: 'http://192.168.110.217:8085/cloud_zspfsc_api_war_exploded/',
  // baseUrl: 'http://test.ydapi.xfshlm.com/',
  // baseUrl: 'https://taoapi.bjfxtx.cn/',
  ApiKey: '38a5d3f2020b0fae2f01065550d26ba7',
  // 喜果正式公众号
  appId: 'wxb40f1b0307f827cc',
  appSecret: 'b93cf0ed221ed844173944f025a75876',
  // 测试公众号
  // appId: 'wxbb36d843aa1f1635',
  // appSecret: 'a98b70a5cc6e90bd513c50f0830780c4',
  shopId: '22516',
  // shopId: '9082',
  cityId: 1137
}

export const post = (url, data) => {
  // 处理header信息
  var header = {}
  if (!data.isJson) {
    header = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  } else {
    ajaxParam(data)
    var str = 'Fx-Timestamp' + '=' + data['Fx-Timestamp'] + config.ApiKey
    var Sign = md5(str)
    header = {
      'Fx-Timestamp': data['Fx-Timestamp'],
      'Fx-Sign': Sign,
      'Content-Type': 'application/json;charset=UTF-8'
    }

    data.time = new Date().getTime()
    data['Fx-Sign'] = Sign
  }
  return new Promise((resolve, reject) => {
    axios.post(url, data, {
      baseURL: config.baseUrl,
      headers: header
    }).then((res) => {
      resolve(res)
    }, err => {
      reject(err)
    })
  })
}
export const ajaxParam = (param) => {
  param['Fx-Timestamp'] = new Date().getTime()
  var paramStr = ''
  var keys = []
  // 参数名排序
  for (var key in param) {
    keys.push(key)
  }
  keys.sort()
  for (var index in keys) {
    var key2 = keys[index]
    var str = param[key2]
    if (str !== null && str.length !== 0) {
      if (paramStr.length === 0) {
        paramStr = (key2 + '=' + str)
      } else {
        paramStr = paramStr + '&' + (key2 + '=' + str)
      }
    } else {
      delete param[key2]
    }
  }
  if (paramStr.length === 0) {
    paramStr = config.ApiKey
  } else {
    paramStr = paramStr + config.ApiKey
  }
  param.sign = md5(paramStr)
}
export const formatTime = (date) => {
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var day = date.getDate()

  var hour = date.getHours()
  var minute = date.getMinutes()
  var second = date.getSeconds()
  return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}
export const formatTimeDate = (date) => {
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var day = date.getDate()
  return [year, month, day].map(formatNumber).join('-')
}
function formatNumber (n) {
  n = n.toString()
  return n[1] ? n : '0' + n
}
