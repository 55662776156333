import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
import './style/base.css'
// import Vant from 'vant'
import 'vant/lib/index.css'
import { Button, Toast, Lazyload, List, Dialog, Swipe, SwipeItem, Tab, Tabs } from 'vant'

createApp(App).use(Lazyload, {
  loading: require('../src/images/default_goods.png')
}).use(Button).use(Toast).use(List).use(Dialog).use(Swipe).use(SwipeItem).use(Tab).use(Tabs).use(store).use(router).mount('#app')
